.menu {
  position: relative;
  margin-bottom: 48px;

  > div {
    position: relative !important;
  }
}

.hide-on-mobile {
  display: none;
}
