@import "../../../common/vars";

.option {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.optionFocused {
  background: $athens-grey;
  cursor: pointer;
}

.type {
  border-radius: 4px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: $small-font-size;
  padding: 2px 6px;
  color: $white;
}

.typeExistingDesign {
  background: $submarine;
}

.typeOwnDesign {
  background: $medium-sea-green;
}
