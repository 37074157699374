@import "../../../common/vars";
@import "../../../common/breakpoints";

.container {
  position: relative;
  flex: 1 1 auto;
  margin-right: 10px;

  @include bp-min($md) {
    margin-right: 30px;
  }

  @include bp-min($lg) {
    margin-right: 60px;
  }
}

.icon {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 15px;
  color: $charade;
}
